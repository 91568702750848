import React, { useState } from 'react';

const BoatLoanCalculator = () => {
  const [loanAmount, setLoanAmount] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [loanTerm, setLoanTerm] = useState('');
  const [monthlyPayment, setMonthlyPayment] = useState(null);
  const [paymentSchedule, setPaymentSchedule] = useState([]);

  const calculateMonthlyPayment = (e) => {
    e.preventDefault();
    if (loanAmount && interestRate && loanTerm) {
      const principal = parseFloat(loanAmount);
      const monthlyInterestRate = parseFloat(interestRate) / 100 / 12;
      const numberOfPayments = parseFloat(loanTerm) * 12;

      // Calculate monthly payment using the amortization formula
      const payment =
        (principal * monthlyInterestRate) /
        (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));

      setMonthlyPayment(payment.toFixed(2));

      // Generate detailed payment schedule
      let remainingBalance = principal;
      const schedule = [];

      for (let i = 0; i < numberOfPayments; i++) {
        const interestPayment = remainingBalance * monthlyInterestRate;
        const principalPayment = payment - interestPayment;
        remainingBalance -= principalPayment;

        // Ensure no negative remaining balance
        if (remainingBalance < 0) remainingBalance = 0;

        // Add payment breakdown to schedule
        schedule.push({
          date: new Date(new Date().setMonth(new Date().getMonth() + i)).toLocaleString('en-US', { month: 'short', year: 'numeric' }),
          principal: principalPayment.toFixed(2),
          interest: interestPayment.toFixed(2),
          total: payment.toFixed(2),
          balance: remainingBalance.toFixed(2),
        });
      }

      setPaymentSchedule(schedule);
    }
  };

  return (
    <div className="container mx-auto p-8">
      <h2 className="text-3xl font-bold text-center mb-6">Boat Loan Calculator</h2>

      <form onSubmit={calculateMonthlyPayment} className="mb-8">
        <div className="mb-4">
          <label className="block text-lg font-medium">Boat Loan Amount</label>
          <input
            type="number"
            value={loanAmount}
            onChange={(e) => setLoanAmount(e.target.value)}
            placeholder="$"
            className="border rounded w-full p-2"
          />
        </div>
        <div className="mb-4">
          <label className="block text-lg font-medium">Annual Interest Rate (%)</label>
          <input
            type="number"
            value={interestRate}
            onChange={(e) => setInterestRate(e.target.value)}
            placeholder="%"
            className="border rounded w-full p-2"
          />
        </div>
        <div className="mb-4">
          <label className="block text-lg font-medium">Loan Term (years)</label>
          <input
            type="number"
            value={loanTerm}
            onChange={(e) => setLoanTerm(e.target.value)}
            placeholder="Years"
            className="border rounded w-full p-2"
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white p-2 rounded"
        >
          Calculate Monthly Payment
        </button>
      </form>

      {monthlyPayment && (
        <div className="text-center mt-6">
          <h4 className="text-xl font-semibold">Monthly Payment</h4>
          <p className="text-2xl font-bold">${monthlyPayment}</p>
        </div>
      )}

      {/* Detailed Payment Schedule */}
      {paymentSchedule.length > 0 && (
        <div className="mt-8">
          <h4 className="text-2xl font-semibold mb-4">Detailed Payment Schedule</h4>
          <div className="overflow-y-auto h-64 border border-gray-300 rounded">
            <table className="table-auto w-full border-collapse">
              <thead>
                <tr className="bg-gray-200">
                  <th className="border border-gray-300 px-4 py-2">Payment Date</th>
                  <th className="border border-gray-300 px-4 py-2">Principal</th>
                  <th className="border border-gray-300 px-4 py-2">Interest</th>
                  <th className="border border-gray-300 px-4 py-2">Monthly Total</th>
                  <th className="border border-gray-300 px-4 py-2">Principal Balance</th>
                </tr>
              </thead>
              <tbody>
                {paymentSchedule.map((payment, index) => (
                  <tr key={index}>
                    <td className="border border-gray-300 px-4 py-2">{payment.date}</td>
                    <td className="border border-gray-300 px-4 py-2">${payment.principal}</td>
                    <td className="border border-gray-300 px-4 py-2">${payment.interest}</td>
                    <td className="border border-gray-300 px-4 py-2">${payment.total}</td>
                    <td className="border border-gray-300 px-4 py-2">${payment.balance}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default BoatLoanCalculator;
