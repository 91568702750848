import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PersonalLoanCalculator from './components/PersonalLoanCalculator';
import InterestOnlyLoanCalculator from './components/InterestOnlyLoanCalculator';
import DividendCalculator from './components/DividendCalculator';
import CdInterestCalculator from './components/CdInterestCalculator';
import BoatLoanCalculator from './components/BoatLoanCalculator';
import RVLoanCalculator from './components/RVLoanCalculator';
import OvertimeCalculator from './components/OvertimeCalculator'
import SimpleInterestCalculator from './components/SimpleInterestCalculator';
// import BondCalculator from './components/BondCalculator';


function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <main className="flex-grow container mx-auto px-4 py-8">
          <Routes>
            <Route path="/" element={<PersonalLoanCalculator />} />
            <Route
              path="/interest-only-loan-calculator"
              element={<InterestOnlyLoanCalculator />}
            />
            <Route path="/dividend-calculator" element={<DividendCalculator />} />

            <Route path="/cdInterest-calculator" element={<CdInterestCalculator />} />

            <Route path="/BoatLoan-Calculator" element={<BoatLoanCalculator />} />

            <Route path="/RVLoan-Calculator" element={<RVLoanCalculator />} />

            
            <Route path="/Overtime-Calculator" element={<OvertimeCalculator />} />
            <Route path="/simpleInterest-Calculator" element={<SimpleInterestCalculator />} />


            {/* <Route path="/Bond-Calculator" element={<BondCalculator />} /> */}

          </Routes>
        </main>

        <footer className="bg-gray-100 py-4 mt-auto">
          <p className="text-center text-sm text-gray-500">
            &copy; 2024 Tip Calculator. All rights reserved.
          </p>
        </footer>
      </div>
    </Router>
  );
}

export default App;