import React, { useState } from 'react';
import { LineChart, Line, PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const DividendCalculator = () => {
  const [principal, setPrincipal] = useState(10000);
  const [annualContribution, setAnnualContribution] = useState(1000);
  const [years, setYears] = useState(10);
  const [dividendYield, setDividendYield] = useState(4);
  const [data, setData] = useState([]);
  const [pieData, setPieData] = useState([]);

  const calculateDividends = () => {
    let currentPrincipal = principal;
    const newData = [];
    let totalContributions = 0;
    let totalDividends = 0;

    for (let year = 1; year <= years; year++) {
      const dividendEarned = currentPrincipal * (dividendYield / 100);
      totalDividends += dividendEarned;
      currentPrincipal += dividendEarned + annualContribution;
      totalContributions += annualContribution;

      newData.push({
        year,
        principal: Number(currentPrincipal.toFixed(2)),
        dividends: Number(dividendEarned.toFixed(2)),
      });
    }

    setData(newData);

    const newPieData = [
      { name: 'Initial Investment', value: principal },
      { name: 'Additional Contributions', value: totalContributions },
      { name: 'Dividends Earned', value: totalDividends },
    ];
    setPieData(newPieData);
  };

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold text-right mb-6">Dividend Calculator</h2>
      <div className="grid grid-cols-2 gap-4 mb-6">
        <div>
          <label className="block text-sm font-small text-gray-700 mb-1 text-right">Initial Investment ($)</label>
          <input
            type="number"
            value={principal}
            onChange={(e) => setPrincipal(Number(e.target.value))}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1 text-right">Annual Contribution ($)</label>
          <input
            type="number"
            value={annualContribution}
            onChange={(e) => setAnnualContribution(Number(e.target.value))}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Years</label>
          <input
            type="number"
            value={years}
            onChange={(e) => setYears(Number(e.target.value))}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Dividend Yield (%)</label>
          <input
            type="number"
            value={dividendYield}
            onChange={(e) => setDividendYield(Number(e.target.value))}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
      </div>
      <button 
        onClick={calculateDividends} 
        className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition-colors"
      >
        Calculate
      </button>
      
      {data.length > 0 && (
        <div className="mt-8">
          <h3 className="text-xl font-semibold mb-4">Results</h3>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <h4 className="text-lg font-semibold mb-2">Growth Over Time</h4>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={data}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="year" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="principal" stroke="#8884d8" name="Total Investment" />
                  <Line type="monotone" dataKey="dividends" stroke="#82ca9d" name="Annual Dividends" />
                </LineChart>
              </ResponsiveContainer>
            </div>
            <div>
              <h4 className="text-lg font-semibold mb-2">Final Value Breakdown</h4>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={pieData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey="value"
                    label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                  >
                    {pieData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className="mt-4">
            <p className="font-semibold">Final Investment Value: ${data[data.length - 1].principal.toLocaleString()}</p>
            <p className="font-semibold">Total Dividends Earned: ${pieData[2]?.value.toLocaleString()}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default DividendCalculator;