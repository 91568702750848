import React, { useState, useEffect } from 'react';

const SimpleInterestCalculator = () => {
  const [principal, setPrincipal] = useState(20000);
  const [rate, setRate] = useState(3);
  const [term, setTerm] = useState(10);
  const [endBalance, setEndBalance] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [yearlyData, setYearlyData] = useState([]);

  const calculateInterest = () => {
    const interest = (principal * rate * term) / 100;
    const balance = principal + interest;
    setTotalInterest(interest);
    setEndBalance(balance);

    const yearlyDataCalc = [];
    for (let year = 0; year <= term; year++) {
      const yearlyInterest = (principal * rate * year) / 100;
      yearlyDataCalc.push({
        year,
        balance: principal + yearlyInterest,
        interest: yearlyInterest,
      });
    }
    setYearlyData(yearlyDataCalc);
  };

  useEffect(() => {
    calculateInterest();
  }, [principal, rate, term]);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
  };

  const handleInputChange = (setter) => (e) => {
    const value = e.target.value;
    if (value === '' || (Number(value) >= 0 && Number(value) <= 1000000)) {
      setter(Number(value));
    }
  };

  const BarChart = ({ data }) => {
    const maxBalance = Math.max(...data.map(d => d.balance));
    const height = 200;
    const width = 300;
    const barWidth = width / data.length;

    return (
      <svg width={width} height={height}>
        {data.map((d, i) => (
          <g key={i}>
            <rect
              x={i * barWidth}
              y={height - (d.balance / maxBalance) * height}
              width={barWidth - 1}
              height={(d.balance / maxBalance) * height}
              fill="#4CAF50"
            />
            <rect
              x={i * barWidth}
              y={height - ((d.balance - d.interest) / maxBalance) * height}
              width={barWidth - 1}
              height={(d.interest / maxBalance) * height}
              fill="#2196F3"
            />
          </g>
        ))}
      </svg>
    );
  };

  const PieChart = ({ principal, interest }) => {
    const total = principal + interest;
    const principalAngle = (principal / total) * 360;
    const interestAngle = (interest / total) * 360;

    return (
      <svg width="200" height="200" viewBox="-1 -1 2 2">
        <circle cx="0" cy="0" r="1" fill="#4CAF50" />
        <path
          d={`M 0 0 L 1 0 A 1 1 0 ${interestAngle > 180 ? 1 : 0} 1 
            ${Math.cos((interestAngle * Math.PI) / 180)} 
            ${-Math.sin((interestAngle * Math.PI) / 180)} Z`}
          fill="#2196F3"
        />
      </svg>
    );
  };

  return (
    <div style={{
      fontFamily: 'Arial, sans-serif',
      maxWidth: '600px',
      margin: '20px auto',
      padding: '20px',
      boxShadow: '0 0 10px rgba(0,0,0,0.1)',
      borderRadius: '8px'
    }}>
      <h2 style={{ textAlign: 'center', color: '#333' }}>Simple Interest Calculator</h2>
      <div style={{ marginBottom: '15px' }}>
        <label style={{ display: 'block', marginBottom: '5px' }}>
          Principal:
          <input
            type="number"
            value={principal}
            onChange={handleInputChange(setPrincipal)}
            style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }}
          />
        </label>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <label style={{ display: 'block', marginBottom: '5px' }}>
          Interest rate (%):
          <input
            type="number"
            value={rate}
            onChange={handleInputChange(setRate)}
            style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }}
          />
        </label>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <label style={{ display: 'block', marginBottom: '5px' }}>
          Term (years):
          <input
            type="number"
            value={term}
            onChange={handleInputChange(setTerm)}
            style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ddd' }}
          />
        </label>
      </div>
      <button 
        onClick={calculateInterest}
        style={{
          width: '100%',
          padding: '10px',
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer'
        }}
      >
        Calculate
      </button>
      <div style={{ marginTop: '20px', backgroundColor: '#f9f9f9', padding: '15px', borderRadius: '4px' }}>
        <p><strong>End Balance:</strong> {formatCurrency(endBalance)}</p>
        <p><strong>Total Interest:</strong> {formatCurrency(totalInterest)}</p>
      </div>
      <h3 style={{ marginTop: '20px', textAlign: 'center' }}>Balance Accumulation Graph</h3>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <BarChart data={yearlyData} />
      </div>
      <h3 style={{ marginTop: '20px', textAlign: 'center' }}>Principal vs Interest Breakdown</h3>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <PieChart principal={principal} interest={totalInterest} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        <div style={{ marginRight: '20px' }}>
          <span style={{ backgroundColor: '#4CAF50', width: '20px', height: '20px', display: 'inline-block', marginRight: '5px' }}></span>
          Principal
        </div>
        <div>
          <span style={{ backgroundColor: '#2196F3', width: '20px', height: '20px', display: 'inline-block', marginRight: '5px' }}></span>
          Interest
        </div>
      </div>
    </div>
  );
};

export default SimpleInterestCalculator;