import React, { useState } from "react";

const InterestOnlyLoanCalculator = () => {
  const [loanAmount, setLoanAmount] = useState(0);
  const [interestRate, setInterestRate] = useState(0);
  const [term, setTerm] = useState(0);
  const [interestOnlyPeriod, setInterestOnlyPeriod] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [monthlyPayment, setMonthlyPayment] = useState(null);
  const [principalAndInterestPayment, setPrincipalAndInterestPayment] =
    useState(null);
  const [totalInterest, setTotalInterest] = useState(null);
  const [amortizationSchedule, setAmortizationSchedule] = useState([]);

  const handleCalculate = (e) => {
    e.preventDefault();

    const interestDecimal = interestRate / 100;
    const interestOnlyPayment = (loanAmount * interestDecimal) / 12;

    // Principal & Interest Payment Calculation
    const remainingTerm = term - interestOnlyPeriod;
    const monthlyRate = interestDecimal / 12;
    const principalAndInterestPayment =
      (loanAmount * monthlyRate) /
      (1 - Math.pow(1 + monthlyRate, -remainingTerm * 12));

    // Total interest paid
    const totalInterest =
      interestOnlyPayment * interestOnlyPeriod * 12 +
      (principalAndInterestPayment * remainingTerm * 12 - loanAmount);

    // Generate Amortization Schedule (simplified)
    const schedule = [];
    let balance = loanAmount;

    // Extract year, month from startDate
    const startYear = new Date(startDate).getFullYear();
    const startMonth = new Date(startDate).getMonth(); // 0-indexed (0 = January)
    const startDay = new Date(startDate).getDate();

    for (let year = 0; year < term; year++) {
      for (let month = 0; month < 12; month++) {
        const paymentMonth = startMonth + month + year * 12;
        const paymentYear = startYear + Math.floor(paymentMonth / 12);
        const currentMonth = paymentMonth % 12;

        // Payment Date formatting
        const paymentDate = new Date(paymentYear, currentMonth, startDay);
        const formattedDate = `${String(paymentDate.getDate()).padStart(
          2,
          "0"
        )}-${String(paymentDate.getMonth() + 1).padStart(
          2,
          "0"
        )}-${paymentDate.getFullYear()}`;

        if (year < interestOnlyPeriod) {
          schedule.push({
            paymentDate: formattedDate,
            principal: 0,
            interest: interestOnlyPayment * 12,
            totalPaid: interestOnlyPayment * 12,
            balance,
          });
        } else {
          const principalPaid =
            principalAndInterestPayment * 12 - interestOnlyPayment * 12;
          balance -= principalPaid;
          schedule.push({
            paymentDate: formattedDate,
            principal: principalPaid,
            interest: interestOnlyPayment * 12,
            totalPaid: principalAndInterestPayment * 12,
            balance,
          });
        }
      }
    }

    setMonthlyPayment(interestOnlyPayment.toFixed(2));
    setPrincipalAndInterestPayment(principalAndInterestPayment.toFixed(2));
    setTotalInterest(totalInterest.toFixed(2));
    setAmortizationSchedule(schedule);
  };

  return (
    <div className="bg-white shadow-lg rounded-3xl p-6 sm:p-8 lg:p-12 border border-gray-300">
      <h1 className="text-3xl font-bold text-center mb-6 text-green-600">
        Interest-Only Loan Calculator
      </h1>
      <form onSubmit={handleCalculate} className="space-y-4">
        {/* Input Fields */}
        <div className="input-group">
          <label htmlFor="loanAmount" className="block text-gray-700">
            Loan Amount ($)
          </label>
          <input
            type="number"
            id="loanAmount"
            value={loanAmount === 0 ? "" : loanAmount}
            onChange={(e) => setLoanAmount(Number(e.target.value))}
            required
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
          />
        </div>

        <div className="input-group">
          <label htmlFor="interestRate" className="block text-gray-700">
            Interest Rate (%)
          </label>
          <input
            type="number"
            id="interestRate"
            step="0.01"
            value={interestRate === 0 ? "" : interestRate}
            onChange={(e) => setInterestRate(Number(e.target.value))}
            required
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
          />
        </div>

        <div className="input-group">
          <label htmlFor="term" className="block text-gray-700">
            Term (Years)
          </label>
          <input
            type="number"
            id="term"
            value={term === 0 ? "" : term}
            onChange={(e) => setTerm(Number(e.target.value))}
            required
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
          />
        </div>

        <div className="input-group">
          <label htmlFor="interestOnlyPeriod" className="block text-gray-700">
            Interest-Only Period (Years)
          </label>
          <input
            type="number"
            id="interestOnlyPeriod"
            value={interestOnlyPeriod === 0 ? "" : interestOnlyPeriod}
            onChange={(e) => setInterestOnlyPeriod(Number(e.target.value))}
            required
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
          />
        </div>

        <div className="input-group">
          <label htmlFor="startDate" className="block text-gray-700">
            Start Date
          </label>
          <input
            type="date"
            id="startDate"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            required
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
          />
        </div>

        <button
          type="submit"
          className="w-full bg-green-600 text-white py-3 rounded-lg hover:bg-green-700 transition duration-200"
        >
          Calculate
        </button>
      </form>

      {monthlyPayment !== null && (
        <div className="mt-6 p-4 bg-green-100 border border-green-300 rounded-lg text-center">
          <h2 className="text-xl font-semibold">
            Monthly Interest-Only Payment: ${monthlyPayment}
          </h2>
          <h2 className="text-xl font-semibold">
            Principal & Interest Payment: ${principalAndInterestPayment}
          </h2>
          <h2 className="text-xl font-semibold">
            Total Interest Paid: ${totalInterest}
          </h2>
          <h2 className="text-xl font-semibold">
            Pay-Off Date: {parseInt(startDate.split("-")[0]) + term}
          </h2>
        </div>
      )}

      {amortizationSchedule.length > 0 && (
        <div className="mt-6 bg-white shadow-md rounded-lg">
          {/* Table Title */}
          <h2 className="text-lg font-bold bg-gray-800 text-white px-4 py-3 rounded-t-lg">
            Detailed Payment Schedule
          </h2>

          {/* Scrollable Table Container */}
          <div className="overflow-y-auto max-h-96">
            <table className="min-w-full table-auto">
              {/* Table Header */}
              <thead className="sticky top-0 bg-gray-100">
                <tr>
                  <th className="px-6 py-3 text-gray-600 text-left uppercase tracking-wider">
                    Payment Date
                  </th>
                  <th className="px-6 py-3 text-gray-600 text-left uppercase tracking-wider">
                    Principal
                  </th>
                  <th className="px-6 py-3 text-gray-600 text-left uppercase tracking-wider">
                    Interest
                  </th>
                  <th className="px-6 py-3 text-gray-600 text-left uppercase tracking-wider">
                    Monthly Total
                  </th>
                  <th className="px-6 py-3 text-gray-600 text-left uppercase tracking-wider">
                    Principal Balance
                  </th>
                </tr>
              </thead>

              {/* Table Body */}
              <tbody className="bg-white divide-y divide-gray-200">
                {amortizationSchedule.map((row, index) => (
                  <tr key={index}>
                    <td className="px-9 py-6 whitespace-nowrap">
                      {row.paymentDate}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      ${row.principal.toFixed(2)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      ${row.interest.toFixed(2)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      ${row.totalPaid.toFixed(2)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      ${row.balance.toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default InterestOnlyLoanCalculator;
