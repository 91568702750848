import React, { useState } from 'react';

const CdInterestCalculator = () => {
  const [principal, setPrincipal] = useState('');
  const [cdInterestRate, setCdInterestRate] = useState('');
  const [cdYears, setCdYears] = useState('');
  const [compoundFrequency, setCompoundFrequency] = useState('1');
  const [cdResult, setCdResult] = useState(null);

  // Function to calculate CD interest
  const calculateCdInterest = (e) => {
    e.preventDefault();
    if (principal && cdInterestRate && cdYears && compoundFrequency) {
      const P = parseFloat(principal);
      const r = parseFloat(cdInterestRate) / 100;
      const t = parseFloat(cdYears);
      const n = parseFloat(compoundFrequency);

      const A = P * Math.pow(1 + r / n, n * t); // Compound interest formula
      setCdResult(A.toFixed(2)); // Display result rounded to 2 decimal places
    }
  };

  return (
    <div className="container mx-auto p-8">
      <h2 className="text-3xl font-bold text-center mb-6">CD Interest Calculator</h2>

      <form onSubmit={calculateCdInterest} className="mb-8">
        <div className="mb-4">
          <label className="block text-lg font-medium">Principal (Initial Deposit)</label>
          <input
            type="number"
            value={principal}
            onChange={(e) => setPrincipal(e.target.value)}
            placeholder="$"
            className="border rounded w-full p-2"
          />
        </div>
        <div className="mb-4">
          <label className="block text-lg font-medium">Annual Interest Rate (%)</label>
          <input
            type="number"
            value={cdInterestRate}
            onChange={(e) => setCdInterestRate(e.target.value)}
            placeholder="%"
            className="border rounded w-full p-2"
          />
        </div>
        <div className="mb-4">
          <label className="block text-lg font-medium">Number of Years</label>
          <input
            type="number"
            value={cdYears}
            onChange={(e) => setCdYears(e.target.value)}
            placeholder="Years"
            className="border rounded w-full p-2"
          />
        </div>
        <div className="mb-4">
          <label className="block text-lg font-medium">Compounding Frequency</label>
          <select
            value={compoundFrequency}
            onChange={(e) => setCompoundFrequency(e.target.value)}
            className="border rounded w-full p-2"
          >
            <option value="1">Annually</option>
            <option value="4">Quarterly</option>
            <option value="12">Monthly</option>
          </select>
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white p-2 rounded"
        >
          Calculate CD Interest
        </button>
      </form>

      {cdResult && (
        <div className="text-center mt-6">
          <h4 className="text-xl font-semibold">CD Maturity Amount</h4>
          <p className="text-2xl font-bold">${cdResult}</p>
        </div>
      )}
    </div>
  );
};

export default CdInterestCalculator;
