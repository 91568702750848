import React, { useState, useEffect } from 'react';
import Input from './ui/Input';
import { Card, CardContent, CardHeader, CardTitle } from './ui/Card';
import * as Select from '@radix-ui/react-select';

const OvertimeCalculator = () => {
  const [regularPay, setRegularPay] = useState('');
  const [regularHours, setRegularHours] = useState('40');
  const [regularPayPeriod, setRegularPayPeriod] = useState('hour');
  const [regularWorkPeriod, setRegularWorkPeriod] = useState('week');
  const [totalRegularPayPeriod, setTotalRegularPayPeriod] = useState('month');
  const [overtimeMultiplier, setOvertimeMultiplier] = useState('1.5');
  const [overtimePay, setOvertimePay] = useState('');
  const [overtimePayPeriod, setOvertimePayPeriod] = useState('hour');
  const [overtimeHours, setOvertimeHours] = useState('');
  const [overtimeHoursPeriod, setOvertimeHoursPeriod] = useState('month');
  const [totalOvertimePayPeriod, setTotalOvertimePayPeriod] = useState('month');
  const [totalPay, setTotalPay] = useState(0);
  const [totalPayPeriod, setTotalPayPeriod] = useState('month');

  // Calculate total regular pay
  const calculateTotalRegularPay = () => {
    return (parseFloat(regularPay) || 0) * (parseFloat(regularHours) || 0);
  };

  // Calculate total overtime pay
  const calculateTotalOvertimePay = () => {
    return (parseFloat(overtimePay) || 0) * (parseFloat(overtimeHours) || 0);
  };

  useEffect(() => {
    const calculatedTotalPay = calculateTotalRegularPay() + calculateTotalOvertimePay();
    setTotalPay(calculatedTotalPay);
  }, [regularPay, regularHours, overtimePay, overtimeHours]);

  const TimePeriodSelect = ({ value, onChange, options }) => (
    <Select.Root value={value} onValueChange={onChange}>
      <Select.Trigger className="flex items-center w-[120px] px-4 py-2 text-sm border border-gray-300 rounded-lg hover:border-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500">
        <Select.Value className="mr-2">{value}</Select.Value>
        <Select.Icon />
      </Select.Trigger>
      <Select.Content className="bg-white shadow-lg rounded-lg">
        <Select.Viewport className="py-2">
          {options.map(option => (
            <Select.Item key={option} value={option} className="p-2 px-4 hover:bg-purple-100 text-gray-700">
              <Select.ItemText>{option}</Select.ItemText>
            </Select.Item>
          ))}
        </Select.Viewport>
      </Select.Content>
    </Select.Root>
  );

  return (
    <div className="max-w-md mx-auto space-y-8 p-6 bg-gradient-to-r from-purple-50 to-blue-50 rounded-lg shadow-xl">
      {/* Regular Work Time Section */}
      <Card className="shadow-lg rounded-lg border border-gray-200 bg-gradient-to-br from-white to-purple-50">
        <CardHeader className="bg-gradient-to-r from-purple-50 to-purple-100 p-4 rounded-t-lg">
          <CardTitle className="text-lg text-purple-700 font-bold flex justify-between">
            Regular Work Time
            <i className="fa fa-info-circle text-purple-500" aria-hidden="true" />
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-4 p-4">
          <div>
            <label className="text-sm font-semibold text-gray-700">Regular pay</label>
            <div className="flex items-center space-x-2">
              <Input
                type="number"
                value={regularPay}
                onChange={(e) => setRegularPay(e.target.value)}
                className="w-full border border-gray-300 p-2 rounded-lg hover:border-purple-400 focus:outline-none focus:ring-2 focus:ring-purple-500"
                placeholder="0"
              />
              <TimePeriodSelect
                value={regularPayPeriod}
                onChange={setRegularPayPeriod}
                options={['hour', 'day', 'week', 'month']}
              />
            </div>
          </div>

          <div>
            <label className="text-sm font-semibold text-gray-700">Regular work time</label>
            <div className="flex items-center space-x-2">
              <Input
                type="number"
                value={regularHours}
                onChange={(e) => setRegularHours(e.target.value)}
                className="w-full border border-gray-300 p-2 rounded-lg hover:border-purple-400 focus:outline-none focus:ring-2 focus:ring-purple-500"
                placeholder="0"
              />
              <TimePeriodSelect
                value={regularWorkPeriod}
                onChange={setRegularWorkPeriod}
                options={['day', 'week', 'month']}
              />
            </div>
          </div>

          <div>
            <label className="text-sm font-semibold text-gray-700">Total regular pay</label>
            <div className="flex items-center space-x-2">
              <Input
                type="number"
                value={calculateTotalRegularPay().toFixed(2)}
                readOnly
                className="w-full bg-gray-100 p-2 border border-gray-300 rounded-lg"
              />
              <TimePeriodSelect
                value={totalRegularPayPeriod}
                onChange={setTotalRegularPayPeriod}
                options={['day', 'week', 'month']}
              />
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Overtime Section */}
      <Card className="shadow-lg rounded-lg border border-gray-200 bg-gradient-to-br from-white to-purple-50">
        <CardHeader className="bg-gradient-to-r from-purple-50 to-purple-100 p-4 rounded-t-lg">
          <CardTitle className="text-lg text-purple-700 font-bold flex justify-between">
            Overtime
            <i className="fa fa-info-circle text-purple-500" aria-hidden="true" />
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-4 p-4">
          <div>
            <label className="text-sm font-semibold text-gray-700">Overtime pay multiplier</label>
            <Input
              type="number"
              value={overtimeMultiplier}
              onChange={(e) => setOvertimeMultiplier(e.target.value)}
              className="w-full border border-gray-300 p-2 rounded-lg hover:border-purple-400 focus:outline-none focus:ring-2 focus:ring-purple-500"
              placeholder="0"
            />
          </div>

          <div>
            <label className="text-sm font-semibold text-gray-700">Overtime pay</label>
            <div className="flex items-center space-x-2">
              <Input
                type="number"
                value={overtimePay}
                onChange={(e) => setOvertimePay(e.target.value)}
                className="w-full border border-gray-300 p-2 rounded-lg hover:border-purple-400 focus:outline-none focus:ring-2 focus:ring-purple-500"
                placeholder="0"
              />
              <TimePeriodSelect
                value={overtimePayPeriod}
                onChange={setOvertimePayPeriod}
                options={['hour', 'day', 'week', 'month']}
              />
            </div>
          </div>

          <div>
            <label className="text-sm font-semibold text-gray-700">Overtime hours</label>
            <div className="flex items-center space-x-2">
              <Input
                type="number"
                value={overtimeHours}
                onChange={(e) => setOvertimeHours(e.target.value)}
                className="w-full border border-gray-300 p-2 rounded-lg hover:border-purple-400 focus:outline-none focus:ring-2 focus:ring-purple-500"
                placeholder="0"
              />
              <TimePeriodSelect
                value={overtimeHoursPeriod}
                onChange={setOvertimeHoursPeriod}
                options={['day', 'week', 'month']}
              />
            </div>
          </div>

          <div>
            <label className="text-sm font-semibold text-gray-700">Total overtime pay</label>
            <div className="flex items-center space-x-2">
              <Input
                type="number"
                value={calculateTotalOvertimePay().toFixed(2)}
                readOnly
                className="w-full bg-gray-100 p-2 border border-gray-300 rounded-lg"
              />
              <TimePeriodSelect
                value={totalOvertimePayPeriod}
                onChange={setTotalOvertimePayPeriod}
                options={['day', 'week', 'month']}
              />
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Total Section */}
      <Card className="shadow-lg rounded-lg border border-gray-200 bg-gradient-to-br from-white to-purple-50">
        <CardHeader className="bg-gradient-to-r from-purple-50 to-purple-100 p-4 rounded-t-lg">
          <CardTitle className="text-lg text-purple-700 font-bold">In Total</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4 p-4">
          <div>
            <label className="text-sm font-semibold text-gray-700">Total pay</label>
            <div className="flex items-center space-x-2">
              <Input
                type="number"
                value={totalPay.toFixed(2)}
                readOnly
                className="w-full bg-gray-100 p-2 border border-gray-300 rounded-lg"
              />
              <TimePeriodSelect
                value={totalPayPeriod}
                onChange={setTotalPayPeriod}
                options={['day', 'week', 'month']}
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default OvertimeCalculator;
