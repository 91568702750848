import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { PieChart, Pie, Cell, Label } from "recharts";

import {
  BrowserRouter as Router,
  // Route,
  // Routes,
  Link,
  // useParams,
} from "react-router-dom";

const PersonalLoanCalculator = () => {
  const [loanAmount, setLoanAmount] = useState(20000);
  const [interestRate, setInterestRate] = useState(10);
  const [loanTerm, setLoanTerm] = useState({ years: 5, months: 0 });
  const [startDate, setStartDate] = useState({ month: "Sep", year: 2024 });
  const [monthlyPay, setMonthlyPay] = useState(0);
  const [totalPayments, setTotalPayments] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);
  const [payoffDate, setPayoffDate] = useState("");
  const [amortizationSchedule, setAmortizationSchedule] = useState([]);
  const [detailedTable, setDetailedTable] = useState([]);
  const [showDetailedTable, setShowDetailedTable] = useState(false); // State to control table visibility

  useEffect(() => {
    calculateLoan();
  }, [loanAmount, interestRate, loanTerm, startDate]);

  const calculateLoan = () => {
    const r = interestRate / 1200;
    const n = loanTerm.years * 12 + loanTerm.months;
    const monthlyPayment =
      (loanAmount * r * Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1);
    const totalPay = monthlyPayment * n;

    setMonthlyPay(monthlyPayment.toFixed(2));
    setTotalPayments(totalPay.toFixed(2));
    setTotalInterest((totalPay - loanAmount).toFixed(2));

    const payoffDateObj = new Date(
      startDate.year,
      getMonthIndex(startDate.month) + n,
      1
    );
    setPayoffDate(
      `${getMonthName(
        payoffDateObj.getMonth()
      )}. ${payoffDateObj.getFullYear()}`
    );

    const schedule = [];
    const table = [];
    let balance = loanAmount;
    let year = 1;
    let currentDate = new Date(
      startDate.year,
      getMonthIndex(startDate.month),
      1
    );

    for (let i = 1; i <= n; i++) {
      const interest = balance * r;
      const principal = monthlyPayment - interest;
      balance -= principal;

      if (i % 12 === 1 || i === n) {
        schedule.push({
          year,
          date: `${currentDate.getMonth() + 1}/${currentDate
            .getFullYear()
            .toString()
            .substr(-2)}-${((currentDate.getMonth() + 1) % 12) + 1}/${(
            currentDate.getFullYear() + (currentDate.getMonth() === 11 ? 1 : 0)
          )
            .toString()
            .substr(-2)}`,
          interest: interest.toFixed(2),
          principal: principal.toFixed(2),
          endingBalance: Math.max(balance, 0).toFixed(2),
        });
        year++;
      }

      table.push({
        date: `${getMonthName(
          currentDate.getMonth()
        )} ${currentDate.getFullYear()}`,
        principal: principal.toFixed(2),
        interest: interest.toFixed(2),
        total: monthlyPayment.toFixed(2),
        balance: Math.max(balance, 0).toFixed(2),
      });

      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    setAmortizationSchedule(schedule);
    setDetailedTable(table);
  };

  const getMonthIndex = (monthName) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months.indexOf(monthName);
  };

  const getMonthName = (monthIndex) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[monthIndex];
  };

  const pieChartData = [
    { name: "Loan amount", value: loanAmount },
    { name: "Interest", value: parseFloat(totalInterest) },
  ];

  const COLORS = ["#0088FE", "#00C49F"];

  const lineChartData = amortizationSchedule.map((item, index) => ({
    year: index + 1,
    balance: parseFloat(item.endingBalance),
    interest: parseFloat(item.interest),
    payment: parseFloat(monthlyPay) * 12,
  }));

  return (
    <div className="max-w-4xl mx-auto p-6 bg-gray-100 shadow-lg rounded-lg">
      <h1 className="text-3xl font-bold mb-6 text-blue-800 text-center">
        Personal Loan Calculator
      </h1>

      {/* Container for responsive layout */}
      <div className="flex flex-col lg:flex-row gap-6 mb-8">
        {/* Enter Loan Details Section */}
        <div className="flex-1 bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-lg font-semibold mb-4 bg-blue-700 text-white p-3 rounded">
            Enter Loan Details
          </h2>
          <div className="space-y-4">
            <div>
              <label className="block font-medium text-gray-700">
                Loan amount
              </label>
              <input
                type="number"
                value={loanAmount}
                onChange={(e) => setLoanAmount(parseFloat(e.target.value))}
                className="w-full mt-1 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block font-medium text-gray-700">
                Interest rate (%)
              </label>
              <input
                type="number"
                value={interestRate}
                onChange={(e) => setInterestRate(parseFloat(e.target.value))}
                className="w-full mt-1 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block font-medium text-gray-700">
                Loan term
              </label>
              <div className="flex space-x-2">
                <input
                  type="number"
                  value={loanTerm.years}
                  onChange={(e) =>
                    setLoanTerm({
                      ...loanTerm,
                      years: parseInt(e.target.value),
                    })
                  }
                  className="w-full mt-1 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <span className="self-center">years</span>
                <input
                  type="number"
                  value={loanTerm.months}
                  onChange={(e) =>
                    setLoanTerm({
                      ...loanTerm,
                      months: parseInt(e.target.value),
                    })
                  }
                  className="w-full mt-1 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <span className="self-center">months</span>
              </div>
            </div>
            <div>
              <label className="block font-medium text-gray-700">
                Start date
              </label>
              <div className="flex space-x-2">
                <select
                  value={startDate.month}
                  onChange={(e) =>
                    setStartDate({ ...startDate, month: e.target.value })
                  }
                  className="w-full mt-1 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  {[
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                  ].map((month) => (
                    <option key={month} value={month}>
                      {month}
                    </option>
                  ))}
                </select>
                <input
                  type="number"
                  value={startDate.year}
                  onChange={(e) =>
                    setStartDate({
                      ...startDate,
                      year: parseInt(e.target.value),
                    })
                  }
                  className="w-full mt-1 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            </div>
          </div>
          <button
            onClick={calculateLoan}
            className="mt-6 w-full bg-green-600 text-white p-3 rounded hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
          >
            Calculate
          </button>
        </div>

        {/* Monthly Payment Section */}
        <div className="flex-1 bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-lg font-semibold mb-4 bg-blue-700 text-white p-3 rounded">
            Monthly Payment: ${monthlyPay}
          </h2>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart width={300} height={300}>
              <Pie
                data={pieChartData}
                outerRadius={80}
                dataKey="value"
                labelLine={false}
                paddingAngle={5}
              >
                {pieChartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
                <Label
                  dataKey="value"
                  position="outside"
                  offset={15}
                  fontSize={14}
                  formatter={(value, entry) => `${entry.name}: ${value}`}
                />
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
          <div className="space-y-2 mt-4">
            <p>
              <strong>Total Payments:</strong> ${totalPayments}
            </p>
            <p>
              <strong>Total Interest:</strong> ${totalInterest}
            </p>
            <p>
              <strong>Payoff Date:</strong> {payoffDate}
            </p>
          </div>
        </div>
      </div>

      {/* Amortization Schedule Section */}
      <div className="bg-white p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-lg font-semibold mb-4 bg-blue-700 text-white p-3 rounded">
          Amortization Schedule
        </h2>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={lineChartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="balance"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
            <Line type="monotone" dataKey="interest" stroke="#82ca9d" />
            <Line type="monotone" dataKey="payment" stroke="#ffc658" />
          </LineChart>
        </ResponsiveContainer>

        {/* Button to toggle detailed table */}
        <button
          onClick={() => setShowDetailedTable(!showDetailedTable)}
          className="px-6 py-3 bg-blue-600 text-white rounded-full hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          {showDetailedTable
            ? "Hide Detailed Payment Schedule"
            : "Show Detailed Payment Schedule"}
        </button>
      </div>

      {/* Conditional Rendering of Detailed Table */}
      {showDetailedTable && (
        <div className="bg-white p-6 rounded-lg shadow-md mt-8 max-h-96 overflow-y-auto">
          <h2 className="text-lg font-semibold mb-4 bg-gray-800 text-white p-3 rounded">
            Detailed Payment Schedule
          </h2>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Payment Date
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Principal
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Interest
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Monthly Total
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Principal Balance
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {detailedTable.map((row, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {row.date}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      ${row.principal}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      ${row.interest}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      ${row.total}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      ${row.balance}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <div>
        <h3 className="text-2xl sm:text-3xl mt-6 font-bold mb-6 text-black-600 text-left">
          More Calculators
        </h3>
        <ul className="space-y-4">
          <li>
            <Link
              to="/interest-only-loan-calculator"
              className="text-blue-500 hover:underline"
            >
              Interest Only Loan Calculator
            </Link>
          </li>
          <li>
            <Link to="/dividend-calculator" className="text-blue-500 hover:underline">
              Dividend Calculator
            </Link>
          </li>

          <li>
            <Link to="/cdInterest-calculator" className="text-blue-500 hover:underline">
              CdInterest Calculator
            </Link>
          </li>

          <li>
            <Link to="/BoatLoan-Calculator" className="text-blue-500 hover:underline">
              BoatLoan Calculator
            </Link>
          </li>

          <li>
            <Link to="/RVLoan-Calculator" className="text-blue-500 hover:underline">
              RVLoan Calculator
            </Link>
          </li>

          

          <li>
            <Link to="/Overtime-Calculator" className="text-blue-500 hover:underline">
            Overtime Calculator
            </Link>
          </li>

          

          <li>
            <Link to="/simpleInterest-Calculator" className="text-blue-500 hover:underline">
            Simple Interest Calculator
            </Link>
          </li>

          <li>
            <Link to="/Bond-Calculator" className="text-blue-500 hover:underline">
            Bond Calculator
            </Link>
          </li>

          


          
          {/* Add more links here if needed */}
        </ul>
      </div>
    </div>
  );
};

export default PersonalLoanCalculator;
